import React from 'react';



import Number from '../assets/img/2.png'



const ApiGardens = () => {
  return (
    <section id='ApiGardens' className='pt-24' data-aos='fade-up' data-aos-delay=''>
      <div className='container mx-auto'>
      <h1
              className='text-[32px] lg:text-[32px] font-bold leading-tight mb-6'
              data-aos=''
              data-aos-delay=''
            >
              API Gardens
            </h1>
        <div className='flex flex-col gap-y-6 lg:flex-row lg:justify-between'>
          {/* item */}
          

          <div className='flex items-center gap-x-6' style={{border: '2px solid #AC3CA5', borderRadius:'10px', margin:'10px', padding:'20px', textAlign:'center'}}>
            
            {/* item icon 
            <div className='bg-white/10 w-20 h-20 rounded-full flex items-center justify-center text-blue text-2xl lg:text-4xl'>
              <HiChartBar />
            </div>  */}
            {/* item text */}
            <div>
              <div className='text-2xl font-bold lg:text-[20px] lg:mb-2'>
              ETH large buy-sell order scraper
              </div>
              <div className='text-gray'>The API retrieves large buy/sell orders from the
Ethereum blockchain ( of ERC20 coins ) based on the provided contract address.
Simply replace the contract address in the URL with the desired coin's contract
address to view substantial order data.
</div>
             <br></br>

             <div className='flex flex-col gap-y-2 lg:flex-row' style={{textAlign:'center',  justifyContent:'center'}}>
        
        
          {/* item    
            <center>  <button
              className='btn gap-x-6 pl-2 text-sm lg:h-8 lg:text-base'
              data-aos=''
              data-aos-delay='700' style={{background:'#fff', color:'#000', padding:'20px 30px 20px 30px',borderRadius:'8px', alignItems:'center',margin:'4px', textAlign:'center'}}
            >
              Read Doc
             
            </button></center>  */}


            <center>  
              
         <a href='https://eth-order-scraper.vercel.app/large-orders/0xdAC17F958D2ee523a2206206994597C13D831ec7' target='_blank'> 
          <button 
              className='btn gap-x-6 pl-2 text-sm lg:h-8 lg:text-base'
              data-aos=''
              data-aos-delay='700' style={{background:'#000', border:'1px solid #AC3CA5',padding:'20px 30px 20px 30px',borderRadius:'8px', color:'#fff', margin:'4px', textAlign:'center'}}
            >
              View
             
            </button> </a> 
           
</center>
            
            </div>
            <br></br>
   
            </div>
          </div>
          {/* item */}
 
         
        </div>
        <br></br>

        <div className='text-gray' style={{textAlign:'center'}}>More APIs Coming Soon
</div> 
        
        <br></br><br></br>
        <center> <img src={Number} alt=''     data-aos='fade-up'
    data-aos-delay='' /></center>
      </div>
    </section>
  );
};

export default ApiGardens;
