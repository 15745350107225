import React from 'react'

import Number from '../assets/img/4.png'

// import icons
import {

IoIosAdd,
} from 'react-icons/io';

const comingsoon = () => {
  return (
    <section className='section' data-aos='fade-up'>
      <div className='container mx-auto'>
      <h1
              className='text-[32px] lg:text-[32px] font-bold leading-tight mb-6'
              data-aos=''
              data-aos-delay=''
            >
Coming soon 
           </h1>
            <div className='flex flex-col'>
         
            <div style={{alignItems:'center' ,padding:'35px 0px 0px 0px', borderRadius:'10px'}}>
           <center>
           
            <h1
              className='text-[32px] lg:text-[32px]  leading-tight mb-6'
              data-aos=''
              data-aos-delay='500' style={{textAlign:'center', padding:'40px 0', width:'50%', border: '2px solid #AC3CA5'}}
            >
            <center style={{fontSize:'125px', fontWeight:'800'}}>  <IoIosAdd /> </center>
              Coming Soon <br></br><br></br>
            </h1> </center>
          
            </div>
          
          {/* item */}

            
                   
         
         
        </div>

        <br></br><br></br>
        <center> <img src={Number} alt=''     data-aos='fade-up'
    data-aos-delay='' /></center>
        
      </div>
    </section>
  )
}

export default comingsoon
