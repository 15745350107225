import React from 'react'

const ContactForm = () => {
  return (
    <section className='section' data-aos='fade-up'>
      <div className='container mx-auto'>
<center>
      <h1
              className='text-[32px] lg:text-[32px] font-bold leading-tight mb-6'
              data-aos=''
              data-aos-delay='500'
            >
              Contact Form
            </h1>
           {/* form */}

           
          <form action="sendmail.php"
            className='flex flex-col w-full space-y-4 gap-x-4  lg:space-y-30' style={{width:'50%'}}
            data-aos='fade-up'
            data-aos-offset='' method="post" data-toggle="validator"
          >
            <input className=' h-12 px-4 outline-none rounded-md' name="name" type='text' placeholder='Your name' />
           <div class="help-block with-errors"></div>

            <input  className=' h-12 px-4 outline-none rounded-md' name="email" type='emai' placeholder='Your email' />
<div class="help-block with-errors"></div>
<input  className=' h-12 px-4 outline-none rounded-md' name="phonenumber" type='number' placeholder='Your number' />
<div class="help-block with-errors"></div>
            <br></br>
            <button className='btn'><center>Send</center></button>
          </form> </center>
        </div>
        </section>
  )
}

export default ContactForm
