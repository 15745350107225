import React, { useEffect, useState } from 'react';

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";



// import aos
import Aos from 'aos';
import 'aos/dist/aos.css';

// import components
import Header from './components/Header';
import Hero from './components/Hero';
import NavMobile from './components/NavMobile';
import ApiGardens from './components/ApiGardens';
import Why from './components/Why';

import Footer from './components/Footer';
import NetworkModel from './components/NetworkModel';
import ComingSoon from './components/ComingSoon';
import Web3 from './components/Web3';
import AboutUs from './components/AboutUs';
import HowItWorks from './components/HowItWorks';
import ContactForm from './components/ContactForm';
import Platform from './components/Platform';

const App = () => {
  // mobile nav state
  const [navMobile, setNavMobile] = useState(false);
  // aos init
  useEffect(() => {
    Aos.init({
      duration: 2500,
      delay: 400,
    });
  });
 
  return (
    <div className=''>
      <Header setNavMobile={setNavMobile} />
      <Hero />
      {/* mobile nav  */}
      <div
        className={`${
          navMobile ? 'right-0' : '-right-full'
        } fixed z-10 top-0 h-full transition-all duration-200`}
      >
        <NavMobile setNavMobile={setNavMobile} />
      </div>
      <ApiGardens />
      <Platform/>
      
      <NetworkModel/>
      <Web3/>
      <ComingSoon/>
     
      <AboutUs/>
      <HowItWorks/>
   
      <ContactForm/>
      <Footer />
    </div>
  );  
 
  
};

export default App;
