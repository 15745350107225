import React from 'react'

// import images

import Image from '../assets/img/platform.jpeg';



const Platform = () => {
  

  return (
    <section id='Platform' className='section' data-aos='fade-up' >
      <div className='container mx-auto'>
      <h1
              className='text-[32px] lg:text-[32px] font-bold leading-tight mb-6'
              data-aos=''
              data-aos-delay=''
            >
              Platform
            </h1>

          <br/><br/>




            <div className='flex flex-col gap-y-6 lg:flex-row lg:justify-between' style={{border: '2px solid #AC3CA5',borderRadius:'10px'}}>
          {/* item */}
          

          <div className='flex items-center gap-x-6 platform-1' style={{textAlign:'center', width:'550%'}}>
            
      
            {/* item text */}
            <div>
            <img src={Image} alt='' />
             

            
            </div>
          </div>
          {/* item */}

                   {/* item */}
          

                   <div className='flex items-center gap-x-6' style={{textAlign:'center'}}>
            
           
            {/* item text */}
            <div>
             
              <div className='text-gray' style={{padding:'25px'}}>We are thrilled to share our ongoing project with you - a cutting-edge
Financial Advisor Chatbot. This isn't just any chatbot; we are leveraging the
advanced GPT API, developed by OpenAI, to create a tool that will revolutionize
the way you manage your finances.
Our chatbot is being meticulously fine-tuned to provide you with smart,
personalized financial advice. It will be able to guide you on a range of topics
from savings and investments, to financial laws, tax planning, and much more.
Whether you're an experienced investor or just starting your financial journey, our
chatbot will be capable of providing advice that's tailored to your unique needs
and financial goals.
The GPT API, with its deep learning capabilities, allows our chatbot to
understand and respond to your queries with human-like precision. It's like
having a financial advisor in your pocket, available 24/7 to answer your questions
and guide you towards financial success.
Please note, while our chatbot is being developed to offer the best possible
recommendations, it's important to consult with a certified financial advisor for
major financial decisions.

We can't wait to introduce you to this innovative tool in the near future. Stay
tuned for updates and get ready to take control of your finances like never before!
 </div>
              <br></br>

<div className='flex flex-col gap-y-2 lg:flex-row' style={{textAlign:'center',  justifyContent:'center'}}>
 
</div>
            </div>
          </div>
          {/* item */}
                   
         
         
        </div>


        
      </div>
    </section>
  );
};

export default Platform;
