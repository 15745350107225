import React from 'react'
// import images

import Number from '../assets/img/3.png';

const NetworkModel = () => {
  const sectionStyle = {
    
  };



  return (
    <section id='' className='NetworkModel' data-aos='fade-up'>
      <div className='container mx-auto'><br></br>
      <h1
              className='text-[32px] lg:text-[32px] font-bold leading-tight mb-6'
              data-aos=''
              data-aos-delay=''
            >
              Fine Tuned Chatbots
            </h1>

            <div className='text-2xl font-bold lg:text-[20px] lg:mb-2'>
            “smart way of catering your needs using AI”
              </div>

              <div className='text-gray'>A fleet of specialized chatbots is coming! each trained on
the GPT API developed by OpenAI, to cater to your every need, and act exactly
like chatGPT!
Imagine having your very own Financial Advisor Chatbot for managing your
finances, a Stock Picker Chatbot to navigate the stock market, a Crypto Advisor
Chatbot to guide you through the complexities of cryptocurrencies, and a Tax
Advisor Chatbot to ensure your tax planning is as efficient as possible.
These chatbots, available 24/7, will leverage the power of AI to provide
personalized insights and recommendations with a human-like understanding.
Yet, for significant financial decisions, we always recommend consulting with
certified professionals in the respective fields. 
</div>
<br></br>
            <div className='flex flex-col' style={sectionStyle} >
         
            <div style={{width:''}}>
            
            <div className='flex flex-col gap-y-6 lg:flex-row lg:justify-between'>
          {/* item */}
          

          <div className='flex items-center gap-x-6' style={{border: '2px solid #AC3CA5', borderRadius:'10px', margin:'10px', padding:'20px', textAlign:'center'}}>
            
            {/* item icon 
            <div className='bg-white/10 w-20 h-20 rounded-full flex items-center justify-center text-blue text-2xl lg:text-4xl'>
              <HiChartBar />
            </div>  */}
            {/* item text */}
            <div>
              <div className='text-2xl font-bold lg:text-[20px] lg:mb-2'>
              ETH large buy-sell order scraper
              </div>
              <div className='text-gray'>The API retrieves large buy/sell orders from the
Ethereum blockchain ( of ERC20 coins ) based on the provided contract address.
Simply replace the contract address in the URL with the desired coin's contract
address to view substantial order data.
</div>
             <br></br>

             <div className='flex flex-col gap-y-2 lg:flex-row' style={{textAlign:'center',  justifyContent:'center'}}>
        
         
            <center>  <button
              className='btn gap-x-6 pl-2 text-sm lg:h-8 lg:text-base'
              data-aos=''
              data-aos-delay='700' style={{background:'#fff', color:'#000', padding:'20px 30px 20px 30px',borderRadius:'8px', alignItems:'center',margin:'4px', textAlign:'center'}}
            >
              Read Doc
             
            </button></center> 


            <center>   <button
              className='btn gap-x-6 pl-2 text-sm lg:h-8 lg:text-base'
              data-aos=''
              data-aos-delay='700' style={{background:'#000', border:'1px solid #AC3CA5',padding:'20px 30px 20px 30px',borderRadius:'8px', color:'#fff', margin:'4px', textAlign:'center'}}
            >
              View
             
            </button></center>
            
            </div>
         
            </div>
          </div>
 {/* item */}


          <div className='flex items-center gap-x-6' style={{border: '2px solid #AC3CA5', borderRadius:'10px', margin:'10px', padding:'20px', textAlign:'center'}}>
            
            {/* item icon 
            <div className='bg-white/10 w-20 h-20 rounded-full flex items-center justify-center text-blue text-2xl lg:text-4xl'>
              <HiChartBar />
            </div>  */}
            {/* item text */}
            <div>
              <div className='text-2xl font-bold lg:text-[20px] lg:mb-2'>
              ETH large buy-sell order scraper
              </div>
              <div className='text-gray'>The API retrieves large buy/sell orders from the
Ethereum blockchain ( of ERC20 coins ) based on the provided contract address.
Simply replace the contract address in the URL with the desired coin's contract
address to view substantial order data.
</div>
             <br></br>

             <div className='flex flex-col gap-y-2 lg:flex-row' style={{textAlign:'center',  justifyContent:'center'}}>
        
         
            <center>  <button
              className='btn gap-x-6 pl-2 text-sm lg:h-8 lg:text-base'
              data-aos=''
              data-aos-delay='700' style={{background:'#fff', color:'#000', padding:'20px 30px 20px 30px',borderRadius:'8px', alignItems:'center',margin:'4px', textAlign:'center'}}
            >
              Read Doc
             
            </button></center> 


            <center>   <button
              className='btn gap-x-6 pl-2 text-sm lg:h-8 lg:text-base'
              data-aos=''
              data-aos-delay='700' style={{background:'#000', border:'1px solid #AC3CA5',padding:'20px 30px 20px 30px',borderRadius:'8px', color:'#fff', margin:'4px', textAlign:'center'}}
            >
              View
             
            </button></center>
            
            </div>
         
            </div>
          </div>

           {/* item */}

           <div className='flex items-center gap-x-6' style={{border: '2px solid #AC3CA5', borderRadius:'10px', margin:'10px', padding:'20px', textAlign:'center'}}>
            
            {/* item icon 
            <div className='bg-white/10 w-20 h-20 rounded-full flex items-center justify-center text-blue text-2xl lg:text-4xl'>
              <HiChartBar />
            </div>  */}
            {/* item text */}
            <div>
              <div className='text-2xl font-bold lg:text-[20px] lg:mb-2'>
              ETH large buy-sell order scraper
              </div>
              <div className='text-gray'>The API retrieves large buy/sell orders from the
Ethereum blockchain ( of ERC20 coins ) based on the provided contract address.
Simply replace the contract address in the URL with the desired coin's contract
address to view substantial order data.
</div>
             <br></br>

             <div className='flex flex-col gap-y-2 lg:flex-row' style={{textAlign:'center',  justifyContent:'center'}}>
        
         
            <center>  <button
              className='btn gap-x-6 pl-2 text-sm lg:h-8 lg:text-base'
              data-aos=''
              data-aos-delay='700' style={{background:'#fff', color:'#000', padding:'20px 30px 20px 30px',borderRadius:'8px', alignItems:'center',margin:'4px', textAlign:'center'}}
            >
              Read Doc
             
            </button></center> 


            <center>   <button
              className='btn gap-x-6 pl-2 text-sm lg:h-8 lg:text-base'
              data-aos=''
              data-aos-delay='700' style={{background:'#000', border:'1px solid #AC3CA5',padding:'20px 30px 20px 30px',borderRadius:'8px', color:'#fff', margin:'4px', textAlign:'center'}}
            >
              View
             
            </button></center>
            
            </div>
         
            </div>
          </div>

           {/* item */}
          
          </div>{/* item row section */}

          
          





            </div>
          
      

         
        </div>
        <br></br><br></br>
        <center> <img src={Number} alt=''     data-aos='fade-up'
    data-aos-delay='' /></center>

        
      </div>
    </section>
  )
}

export default NetworkModel
