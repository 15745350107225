import React from 'react'

import Image from '../assets/img/how-it-works-img.png'
const HowItWorks = () => {
  return (
    <section className='section' data-aos='fade-up'>
      <div className='container mx-auto'>
     
            <div className='flex flex-col gap-y-6 lg:flex-row lg:justify-between' style={{border: '2px solid #AC3CA5',borderRadius:'10px'}}>
          {/* item */}
          

          
          {/* item */}

                   {/* item */}
          

                   <div className='flex items-center gap-x-6' style={{textAlign:'center'}}>
            
           
            <div>
              <br></br>
              <div className='text-2xl font-bold lg:text-[20px] lg:mb-2'>
              How it works
              </div>
              <div className='text-gray' style={{padding:'25px'}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
              Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
              when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </div>
              <br></br>


            </div>
          </div>
          {/* item */}
          <div className='flex items-center gap-x-6 platform-1' style={{textAlign:'center', width:'200%'}}>
            
          
            <div>
            <img src={Image} alt='' />
             

            
            </div>
          </div>
         
         
        </div>


        
      </div>
    </section>
  )
}

export default HowItWorks
