// import images



export const navData = [
  {
    name: 'Home',
    href: '/',
  },
  {
    name: 'API Gardens',
    href: '#ApiGardens',
  },
  {
    name: 'Platform',
    href: '#Platform',
  },
  {
    name: 'Web3',
    href: '#Web3',
  },
  {
    name: 'About Us',
    href: '#AboutUs',
  },
];


